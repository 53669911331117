
@media (max-width: 768px) {
  .Contact{
    padding-top: 60px;
  
    .row{
      align-items: center;
  
      .contact-info{
        h2{
          color: #191919;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 42px; /* 125% */

          span{
            color: #1E84C5;
          }
        }
  
        p{
          color: #575757;
          font-size: 17.156px;
          font-style: normal;
          font-weight: 400;
          line-height: 29px; /* 169.035% */
          margin-bottom: 50px;
        }

        .dn{
          display: block;
          margin-bottom: 24px;
        }
  
        button{
          border: none;
  
          display: flex;
          width: 100%;
          height: 60px;
          padding: 14.5px 44.8px 15.5px 45px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 30px;
          background: #FFE02F;
          color: #191919;
          text-align: center;
          font-family: Arial;
          font-size: 17.656px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px; /* 169.912% */
          margin-bottom: 80px;
        }
      }

      .contact-img{
        display: none;
      }
    }
  }

  .Uslugi{
    margin-bottom: 50px;
    .card{
      padding: 12px 16px;
      border-radius: 18px;
      border-bottom: 1px solid #EBEBEB;
      background: #F6F6F6;
      margin-bottom: 12px;

      .arrow{
        display: block;
        position: absolute;
        right: 16px;
        bottom: 16px;
      }
      .d-flex{
        display: block !important;
        justify-content: left;
        
        .bold{
          color: #181818;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 141.176% */
          letter-spacing: -0.34px;
        }
        .small{
          color: #86888F;


          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.34px;
        }
      }
    }
  }

  .Header{
    padding-top: 120px;
    height: auto;
    margin-bottom: 80px;
    .row{
      height: auto;
      align-items: center;
  
      .header-info{
        h2{
          color: #363636;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 42px; /* 116.1% */
          margin-bottom: 50px;
        }

        .dn{
          display: block;
          margin-bottom: 48px;
        }
  
        .d-flex{
          display: block !important;
          gap: 30px;
          margin-bottom: 60px;
  
        }
  
        .card{
          position: relative;
          height: 128px;
          border-radius: 10px;
          background: #FFF;
          box-shadow: 0px 11px 51px 0px rgba(28, 28, 28, 0.09);
          padding: 27px;
          border: none;
  
          .top-stick1{
            position: absolute;
            top: 8px;
            left: 8px;
          }
  
          .top-stick2{
            position: absolute;
            top: 8px;
            right: 8px;
          }
          .card-icon{
            width: 40px;
            height: 40px;
            margin: 0 auto;
          }
  
          p{
            padding-top: 10px;
            color: #31303D;
            text-align: center;
            font-size: 13.477px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 148.406% */
          }
        }
  
        .header-button{
          border-radius: 30px;
          background: #FFE02F;
          box-shadow: 0px 0px 29px 0px #FFE02F;
          color: #191919;
          text-align: center;
          font-size: 20.305px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px; /* 167.449% */
          display: inline-flex;
          padding: 14.5px 20px 15.5px 20px;
          justify-content: center;
          align-items: center;
        }
      }

      .header-img{
        display: none;
        width: 100%;
      }
    }
  }

  .Navbar{
    padding: 29px 0;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    .nav-body{
      display: flex;
      justify-content: space-between;
      .call-btn{
        display: block;
      }
      .nav-logo2{
        display: block;
      }
      .nav__toggler{
        display: block;
        div{
          width: 2.5rem;
          height: 0.2rem;
          margin: 0.4rem;
          background: #000;
        }
      }
      .nav__menu{
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #F6F6F6;
        transform: translateX(100%);
        transition: 0.5s ease-in;
        z-index: 9;
        
        .nav-logo1{
          display: none;
        }
        li{
          list-style-type: none;
          

          a{
            cursor: pointer;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 21px; /* 131.25% */
            text-decoration: none;
          }
        }
      }

      .nav__active{
        transform: translateX(0);
      }
  
      .nav-numbers{
        display: none;
        li{
          list-style-type: none;
          a{
            color: #191919;
            font-size: 17.016px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px; /* 129.293% */
            text-decoration: none;
          }
        }
      }
    }
  }

  .About{
    background: #F5F5F5;
    padding: 65px 0 80px 0;
    h2{
      color: #191919;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 135.33% */
      margin-bottom: 16px;

      span{
        color: #1E84C5;
      }
    }
  
    p{
      color: #4F4F4F;
      font-size: 17.016px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 164.555% */
      margin-bottom: 14px;
    }
  
    .about-icons{
      padding-top: 30px;
      display: flex;
      justify-content: center;
      gap: 57px;
  
      .icon-body{
        text-align: center;
      }
      
      img{
        margin-bottom: 20px;
      }
  
      p{
        color: #4F4F4F;
        text-align: center;
        font-size: 18.906px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 158.678% */
      }
    }
  }

  .Footer{
    background: #1E222E;
    padding: 64px 0;
  
    .footer-list{
      border-bottom: solid 1px rgba(255, 255, 255, 0.15);
      ul{
        display: flex;
        justify-content: center;
        gap: 48px;
        margin-bottom: 48px;
  
        li{
          list-style-type: none;
          a{
            text-decoration: none;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 21px; /* 150% */
          }
        }
      }
    }
  
    .footer-bottom{
      padding-top: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      gap: 47px;
      color: #FFF;
      font-size: 11.625px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.32px; /* 140.387% */
  
      .footer-socials{
        display: flex;
        justify-content: center;
        gap: 21px;
      }
    }
  }


}